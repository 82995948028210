"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getHeightMapVertices = void 0;
var maths_1 = require("./maths");
/**
 * Converts the data of HeightMap to an array of vertices instead.
 * The data in heightmap is stored as an array of cell height values but a
 * heightmap needs vertice heights. Hence the cell height values are converted
 * into vertice heights where each vertice will be the average height of surrounding
 * cells. Note: The data size will be (width + 1) * (height + 1) as a 2 x 2 grid has
 * 3 x 3 vertices
 */
function getHeightMapVertices(heightMap) {
    // Gets the value at a specific index. If it is out of bounds
    // returns 0
    var getCellValue = function (x, y) {
        var _a;
        if (x < 0 || x >= heightMap.width || y < 0 || y >= heightMap.height) {
            return 0;
        }
        return (_a = heightMap.data[y * heightMap.width + x]) !== null && _a !== void 0 ? _a : 0;
    };
    var vertices = Array((heightMap.height + 1) * (heightMap.width + 1)).fill(0);
    for (var y = 0; y < heightMap.height + 1; y += 1) {
        for (var x = 0; x < heightMap.width + 1; x += 1) {
            var topLeftValue = (0, maths_1.avg)(getCellValue(x - 1, y - 1), // Top Left of vertex
            getCellValue(x, y - 1), // Top Right of vertex
            getCellValue(x, y), // Bottom Right of vertex
            getCellValue(x - 1, y) // Bottom Left of vertex
            );
            var topLeftIndex = y * (heightMap.width + 1) + x;
            vertices[topLeftIndex] = topLeftValue;
            var topRightValue = (0, maths_1.avg)(getCellValue(x, y - 1), // Top Left of vertex
            getCellValue(x + 1, y - 1), // Top Right of vertex
            getCellValue(x + 1, y), // Bottom Right of vertex
            getCellValue(x, y) // Bottom Left of vertex
            );
            var topRightIndex = y * (heightMap.width + 1) + x + 1;
            vertices[topRightIndex] = topRightValue;
            var bottomLeftValue = (0, maths_1.avg)(getCellValue(x - 1, y), // Top Left of vertex
            getCellValue(x, y), // Top Right of vertex
            getCellValue(x - 1, y + 1), // Bottom Right of vertex
            getCellValue(x, y + 1) // Bottom Left of vertex
            );
            var bottomLeftIndex = (y + 1) * (heightMap.width + 1) + x;
            vertices[bottomLeftIndex] = bottomLeftValue;
            var bottomRightValue = (0, maths_1.avg)(getCellValue(x, y), // Top Left of vertex
            getCellValue(x + 1, y), // Top Right of vertex
            getCellValue(x + 1, y + 1), // Bottom Right of vertex
            getCellValue(x, y + 1) // Bottom Left of vertex
            );
            var bottomRightIndex = (y + 1) * (heightMap.width + 1) + x + 1;
            vertices[bottomRightIndex] = bottomRightValue;
        }
    }
    return vertices;
}
exports.getHeightMapVertices = getHeightMapVertices;
