import swordAsset from "@assets/weapons/sword.fbx";
import staffAsset from "@assets/weapons/staff.fbx";
import daggerAsset from "@assets/weapons/dagger.fbx";

const WEAPON_SCALE = 1000;

export const WeaponAssetMap = {
  sword: {
    unwrap: true,
    fileName: swordAsset,
    scale: WEAPON_SCALE,
  },
  staff: {
    unwrap: true,
    fileName: staffAsset,
    scale: WEAPON_SCALE,
  },
  dagger: {
    unwrap: true,
    fileName: daggerAsset,
    scale: WEAPON_SCALE,
  },
} as const;
