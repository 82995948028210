"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.vector2FromAngle = exports.vector2Angle = exports.vector2DistanceTo = exports.vector2DistanceToSquared = exports.vertor2FlipOverX = exports.vector2FlipOverY = exports.vector2Rotate = exports.vector2Normalize = exports.vector2Length = exports.vector2LengthSqred = exports.vector2Divide = exports.vector2IsZero = exports.vector2Equal = exports.vector2Multiply = exports.vector2Subtract = exports.vector2Add = exports.isVector2 = void 0;
function isVector2(v) {
    if (typeof v === "object" &&
        v !== null &&
        Object.keys(v).length === 2 &&
        typeof v.x === "number" &&
        typeof v.y === "number") {
        return true;
    }
    return false;
}
exports.isVector2 = isVector2;
function vector2Add(v1, v2) {
    return {
        x: v1.x + v2.x,
        y: v1.y + v2.y,
    };
}
exports.vector2Add = vector2Add;
function vector2Subtract(v1, v2) {
    return {
        x: v1.x - v2.x,
        y: v1.y - v2.y,
    };
}
exports.vector2Subtract = vector2Subtract;
function vector2Multiply(v, scalar) {
    return {
        x: v.x * scalar,
        y: v.y * scalar,
    };
}
exports.vector2Multiply = vector2Multiply;
// Checks if two vectors are equal
// ```ts
// const v1 = { x: 0, y: 0 }
// const v2 = { x: 1, y: 4 }
// const v3 = { x: 1, y: 4 }
//
// vector2Equal(v1, v2) // Returns false
// vector2Equal(v2, v3) // Returns true
// ```
function vector2Equal(v1, v2) {
    return v1.x === v2.x && v1.y === v2.y;
}
exports.vector2Equal = vector2Equal;
// Checks if a vector is a zero vector
// ```ts
// const v1 = { x: 0, y: 0 }
// const v2 = { x: 1, y: 4 }
//
// vector2IsZero(v1) // Returns true
// vector2IsZero(v2) // Returns false
// ```
function vector2IsZero(v) {
    return v.x === 0 && v.y === 0;
}
exports.vector2IsZero = vector2IsZero;
// Divides a vector by a given scalar
// ```ts
// const v1 = { x: 8, y: 4 }
//
// vector2Divde(v1, 2) // Returns { x: 4, y: 2 }
// ```
function vector2Divide(v, scalar) {
    if (scalar === 0) {
        throw new Error("Attempt to divide vector by zero! Vector: ".concat(v));
    }
    return {
        x: v.x / scalar,
        y: v.y / scalar,
    };
}
exports.vector2Divide = vector2Divide;
function vector2LengthSqred(v) {
    return Math.pow(v.x, 2) + Math.pow(v.y, 2);
}
exports.vector2LengthSqred = vector2LengthSqred;
function vector2Length(v) {
    return Math.sqrt(vector2LengthSqred(v));
}
exports.vector2Length = vector2Length;
function vector2Normalize(v) {
    var mag = vector2Length(v);
    if (mag === 0) {
        throw new Error("Attempted to normalize a zero vector2");
    }
    return vector2Divide(v, mag);
}
exports.vector2Normalize = vector2Normalize;
function vector2Rotate(v, radians) {
    return {
        x: Math.cos(radians) * v.x - Math.sin(radians) * v.y,
        y: Math.sin(radians) * v.x + Math.cos(radians) * v.y,
    };
}
exports.vector2Rotate = vector2Rotate;
function vector2FlipOverY(v) {
    return {
        x: v.x * -1,
        y: v.y,
    };
}
exports.vector2FlipOverY = vector2FlipOverY;
function vertor2FlipOverX(v) {
    return {
        x: v.x,
        y: v.y * -1,
    };
}
exports.vertor2FlipOverX = vertor2FlipOverX;
function vector2DistanceToSquared(v1, v2) {
    return Math.pow(v1.x - v2.x, 2) + Math.pow(v1.y - v2.y, 2);
}
exports.vector2DistanceToSquared = vector2DistanceToSquared;
function vector2DistanceTo(v1, v2) {
    return Math.sqrt(vector2DistanceToSquared(v1, v2));
}
exports.vector2DistanceTo = vector2DistanceTo;
function vector2Angle(v) {
    // Note that we want to follow the THREE.Js implementation of this.
    // In THREE.Js, the angle range is from 0 to 360 however atan2
    // returns the angle from 180 to -180
    var angle = Math.atan2(v.y, v.x);
    if (angle < 0) {
        return 2 * Math.PI + angle;
    }
    return angle;
}
exports.vector2Angle = vector2Angle;
function vector2FromAngle(angle, magnitude) {
    if (magnitude === void 0) { magnitude = 1; }
    return {
        x: magnitude * Math.cos(angle),
        y: magnitude * Math.sin(angle),
    };
}
exports.vector2FromAngle = vector2FromAngle;
