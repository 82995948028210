"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CleanUp = void 0;
/**
 * A utility class to cleanup things
 */
var CleanUp = /** @class */ (function () {
    function CleanUp() {
        this.callbacks = [];
    }
    /**
     * Queue a callback to be called when clean up
     */
    CleanUp.prototype.onCleanUp = function (callback) {
        this.callbacks.push(callback);
    };
    /**
     * Cleans up and calls all the callbacks
     */
    CleanUp.prototype.cleanUp = function () {
        var e_1, _a;
        try {
            for (var _b = __values(this.callbacks), _c = _b.next(); !_c.done; _c = _b.next()) {
                var u = _c.value;
                u();
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.callbacks = [];
    };
    return CleanUp;
}());
exports.CleanUp = CleanUp;
