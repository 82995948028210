"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.avg = void 0;
/**
 * Averages the given numbers
 */
function avg() {
    var props = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        props[_i] = arguments[_i];
    }
    return props.reduce(function (i, j) { return i + j; }, 0) / props.length;
}
exports.avg = avg;
