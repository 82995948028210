"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_WINDOW_NAME = exports.BlueprintEditorWindowMode = exports.CameraType = exports.EditorWindowType = void 0;
var EditorWindowType;
(function (EditorWindowType) {
    EditorWindowType["Blueprint"] = "blueprint";
    EditorWindowType["Webview"] = "webview";
    EditorWindowType["Settings"] = "settings";
    EditorWindowType["Code"] = "code";
})(EditorWindowType || (exports.EditorWindowType = EditorWindowType = {}));
var CameraType;
(function (CameraType) {
    CameraType["PerspectiveCamera"] = "perspectiveCamera";
    CameraType["OrthographicCamera"] = "orthographicCamera";
})(CameraType || (exports.CameraType = CameraType = {}));
var BlueprintEditorWindowMode;
(function (BlueprintEditorWindowMode) {
    BlueprintEditorWindowMode["Blueprint"] = "blueprint";
    BlueprintEditorWindowMode["EditHeightMap"] = "editHeightMap";
})(BlueprintEditorWindowMode || (exports.BlueprintEditorWindowMode = BlueprintEditorWindowMode = {}));
exports.DEFAULT_WINDOW_NAME = (_a = {},
    _a[EditorWindowType.Settings] = "Settings",
    _a);
