"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRayCast = void 0;
var react_1 = require("react");
var raycast_1 = require("../utils/raycast");
var useRayCast = function (_a) {
    var canvasRef = _a.canvasRef, scene = _a.scene, camera = _a.camera, onIntersections = _a.onIntersections;
    var raycastRef = (0, react_1.useRef)(new raycast_1.Raycast(canvasRef.current, scene, camera, onIntersections));
    (0, react_1.useEffect)(function () {
        var raycast = raycastRef.current;
        raycast.domElement = canvasRef.current;
        raycast.scene = scene;
        raycast.camera = camera;
        raycast.onIntersections = onIntersections;
    }, [scene, camera, onIntersections, canvasRef]);
    (0, react_1.useEffect)(function () {
        var raycast = raycastRef.current;
        raycast.subscribe();
        return function () {
            raycast.unsubscribe();
        };
    }, []);
    return {
        onCanvasClick: raycastRef.current.raycast,
    };
};
exports.useRayCast = useRayCast;
