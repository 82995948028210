import { CharacterPartsMap } from "./characterMesh.model";
import THREE from "realms-three";
import { buildMixamoBonesMap } from "../utils/mixamo.utils";
import { RGBA } from "realms-utils";

/**
 * Builds bones map
 */
export function buildBonesMap(characterPartsMap: CharacterPartsMap) {
  const {
    headTop,
    leftLeg,
    rightLeg,
    leftLegChild,
    rightLegChild,
    topSpine,
    rightShoulder,
    leftShoulder,
    rightHand,
  } = buildMixamoBonesMap(characterPartsMap.mixamorigHips);

  const leftLegOriginalPosition = leftLeg.position.clone();

  const rightLegOriginalPosition = rightLeg.position.clone();

  const leftLegChildOriginalPosition = leftLegChild.position.clone();

  const rightLegChildOriginalPosition = rightLegChild.position.clone();

  const topSpineOriginalPosition = topSpine.position.clone();

  const rightShoulderOriginalPosition = rightShoulder.position.clone();

  const leftShoulderOriginalPosition = leftShoulder.position.clone();

  return {
    bones: {
      headTop,
      leftLeg,
      rightLeg,
      leftLegChild,
      rightLegChild,
      topSpine,
      rightShoulder,
      leftShoulder,
      rightHand,
    },
    originalPositions: {
      leftLegOriginalPosition,
      rightLegOriginalPosition,
      leftLegChildOriginalPosition,
      rightLegChildOriginalPosition,
      topSpineOriginalPosition,
      rightShoulderOriginalPosition,
      leftShoulderOriginalPosition,
    },
  };
}

export function smoothCharacterGeometry(characterPartsMap: CharacterPartsMap) {
  // Smooth out low poly
  Object.values(characterPartsMap).forEach((child) => {
    smoothGeometry(child);
  });
}

export function smoothGeometry(obj: THREE.Mesh) {
  if (obj.geometry) {
    obj.geometry.deleteAttribute("normal");
    obj.geometry = THREE.STDLIB.mergeVertices(obj.geometry);
    obj.geometry.computeVertexNormals();
  }
}

/*
 * Creates a canvas of a given color
 */
export function createCanvas(width: number, height: number, color: string) {
  const canvas = window.document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
  ctx.save();
  ctx.fillStyle = color;
  ctx.fillRect(0, 0, width, height);
  ctx.restore();
  return {
    canvas,
    ctx,
  };
}

interface Pixel extends RGBA {
  i: number;
}

/*
 * Runs a function for each pixel in the image
 */
export function processImage(
  image: HTMLImageElement,
  pixelMap: (pixel: Pixel) => RGBA,
  options: {
    // Only process pixels at a given index
    targetPixels?: number[];
  } = {}
): HTMLCanvasElement {
  const canvasBuffer = window.document.createElement("canvas");
  canvasBuffer.height = image.height;
  canvasBuffer.width = image.width;
  const ctx = canvasBuffer.getContext("2d") as CanvasRenderingContext2D;
  ctx.drawImage(image, 0, 0);
  const imageData = ctx.getImageData(
    0,
    0,
    canvasBuffer.width,
    canvasBuffer.height
  );

  if (options.targetPixels) {
    for (const i of options.targetPixels) {
      const r = imageData.data[i];
      const g = imageData.data[i + 1];
      const b = imageData.data[i + 2];
      const a = imageData.data[i + 3];
      const newRgba = pixelMap({ r, g, b, a, i });
      imageData.data[i] = newRgba.r;
      imageData.data[i + 1] = newRgba.g;
      imageData.data[i + 2] = newRgba.b;
      imageData.data[i + 3] = newRgba.a;
    }
  } else {
    for (let i = 0; i < imageData.data.length; i += 4) {
      const r = imageData.data[i];
      const g = imageData.data[i + 1];
      const b = imageData.data[i + 2];
      const a = imageData.data[i + 3];
      const newRgba = pixelMap({ r, g, b, a, i });
      imageData.data[i] = newRgba.r;
      imageData.data[i + 1] = newRgba.g;
      imageData.data[i + 2] = newRgba.b;
      imageData.data[i + 3] = newRgba.a;
    }
  }

  ctx.putImageData(imageData, 0, 0);
  return canvasBuffer;
}
