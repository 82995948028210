"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ObjectFieldType = void 0;
var ObjectFieldType;
(function (ObjectFieldType) {
    ObjectFieldType["Vector2"] = "vector2";
    ObjectFieldType["Vector3"] = "vector3";
    ObjectFieldType["Number"] = "number";
    ObjectFieldType["String"] = "string";
    ObjectFieldType["Boolean"] = "boolean";
    ObjectFieldType["Color"] = "color";
    ObjectFieldType["AssetId"] = "assetId";
})(ObjectFieldType || (exports.ObjectFieldType = ObjectFieldType = {}));
