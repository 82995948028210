"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditorSDKFunctionType = void 0;
/**
 * A function call made by the plugin to the editor
 */
var EditorSDKFunctionType;
(function (EditorSDKFunctionType) {
    EditorSDKFunctionType["CreateWebview"] = "createWebview";
    EditorSDKFunctionType["CloseEditorWindows"] = "closeEditorWindows";
    EditorSDKFunctionType["CreatePrimaryTab"] = "createPrimaryTab";
    EditorSDKFunctionType["CreateSelectedObjectTab"] = "createSelectedObjectTab";
    EditorSDKFunctionType["GetEditorTheme"] = "getEditorTheme";
    EditorSDKFunctionType["AddAsset"] = "addAsset";
    EditorSDKFunctionType["GetState"] = "getState";
    EditorSDKFunctionType["OpenModal"] = "openModal";
    EditorSDKFunctionType["GetOpenModalParams"] = "getOpenModalParams";
    EditorSDKFunctionType["CloseModal"] = "closeModal";
    EditorSDKFunctionType["ShowToast"] = "showToast";
    EditorSDKFunctionType["CreateDataObject"] = "createDataObject";
    EditorSDKFunctionType["UpdateDataObject"] = "updateDataObject";
    EditorSDKFunctionType["DeleteDataObjects"] = "deleteDataObjects";
    EditorSDKFunctionType["GetDisplayedBlueprint"] = "getDisplayedBlueprint";
    EditorSDKFunctionType["GetSelectedDataObject"] = "getSelectedDataObject";
    EditorSDKFunctionType["GetDisplayedWindow"] = "getDisplayedWindow";
    EditorSDKFunctionType["GetDataObjects"] = "getDataObjects";
    EditorSDKFunctionType["GetPluginDataObjects"] = "getPluginDataObjects";
    EditorSDKFunctionType["GetBlueprints"] = "getBlueprints";
    EditorSDKFunctionType["ExportRealm"] = "exportRealm";
    EditorSDKFunctionType["CreateSettingsTab"] = "createSettingsTab";
    EditorSDKFunctionType["GetClientState"] = "getClientState";
    EditorSDKFunctionType["SetClientState"] = "setClientState";
    EditorSDKFunctionType["RemoveClientState"] = "removeClientState";
    EditorSDKFunctionType["GetRealmAssetData"] = "getRealmAssetData";
})(EditorSDKFunctionType || (exports.EditorSDKFunctionType = EditorSDKFunctionType = {}));
