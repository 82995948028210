"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScreenSizeListener = exports.ScreenSize = void 0;
var rxjs_1 = require("rxjs");
var windowSize_1 = require("./windowSize");
var ScreenSize;
(function (ScreenSize) {
    ScreenSize["Desktop"] = "desktop";
    ScreenSize["Tablet"] = "tablet";
    ScreenSize["Mobile"] = "mobile";
})(ScreenSize || (exports.ScreenSize = ScreenSize = {}));
var ScreenSizeListener = /** @class */ (function () {
    function ScreenSizeListener() {
        var _this = this;
        var _a;
        this.windowSize = new windowSize_1.WindowSize();
        /**
         * If the url query parameter specifies the screen size, then force that screen size
         */
        this.queryScreenSizeSubject$ = new rxjs_1.BehaviorSubject(null);
        /**
         * The current screen size
         */
        this.screenSize$ = (0, rxjs_1.combineLatest)([
            this.windowSize.width$,
            this.windowSize.height$,
            this.queryScreenSizeSubject$,
        ]).pipe((0, rxjs_1.map)(function (_a) {
            var _b = __read(_a, 3), width = _b[0], height = _b[1], queryScreenSize = _b[2];
            return queryScreenSize !== null && queryScreenSize !== void 0 ? queryScreenSize : _this.calculateScreenSize(width, height);
        }), (0, rxjs_1.distinctUntilChanged)());
        var urlParams = new URLSearchParams(window.location.search);
        this.queryScreenSizeSubject$.next((_a = Object.values(ScreenSize).find(function (s) { return s === urlParams.get("screenSize"); })) !== null && _a !== void 0 ? _a : null);
    }
    /**
     * Gets the current screen size
     */
    ScreenSizeListener.prototype.getScreenSize = function () {
        return this.calculateScreenSize(window.innerWidth, window.innerHeight);
    };
    /**
     * Calculates the current screen size
     */
    ScreenSizeListener.prototype.calculateScreenSize = function (width, height) {
        var queryScreenSize = this.queryScreenSizeSubject$.getValue();
        if (queryScreenSize) {
            return queryScreenSize;
        }
        var minDeviceLength = Math.min(width, height);
        var regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
        var isMobileAgent = regex.test(navigator.userAgent);
        return minDeviceLength <= 568
            ? ScreenSize.Mobile
            : minDeviceLength <= 744
                ? ScreenSize.Tablet
                : isMobileAgent
                    ? ScreenSize.Tablet
                    : ScreenSize.Desktop;
    };
    /**
     * Subscribe to screen size changes
     */
    ScreenSizeListener.prototype.subscribe = function () {
        this.windowSize.subscribe();
    };
    /**
     * Unsubscribes to screen size changes
     */
    ScreenSizeListener.prototype.unsubscribe = function () {
        this.windowSize.unsubscribe();
    };
    return ScreenSizeListener;
}());
exports.ScreenSizeListener = ScreenSizeListener;
