"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.disposeMaterial = exports.disposeThreeObject = void 0;
/*
 * Recursively disposes the three object. We need to dispose of the following:
 *    - geometry
 *    - materials
 *    - textures
 * https://threejs.org/docs/#manual/en/introduction/How-to-dispose-of-objects
 */
function disposeThreeObject(obj) {
    for (var _i = 0, _a = obj.children; _i < _a.length; _i++) {
        var child = _a[_i];
        disposeThreeObject(child);
    }
    // Dispose texture
    for (var field in obj) {
        var objField = obj[field];
        // The field has a dispose function so call it
        if (typeof objField === "object" &&
            objField !== null &&
            typeof objField.dispose === "function") {
            objField.dispose();
        }
    }
}
exports.disposeThreeObject = disposeThreeObject;
/**
 * Disposes a material
 */
function disposeMaterial(material) {
    if ("map" in material) {
        material.map.dispose();
    }
    material.dispose();
}
exports.disposeMaterial = disposeMaterial;
