"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NinePatchImageEditor = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("@chakra-ui/react");
var FileUploadButton_1 = require("./FileUploadButton");
var react_2 = require("react");
var NinePatchImage_1 = require("./NinePatchImage");
var NinePatchImageEditor = function () {
    var _a;
    var _b = __read((0, react_2.useState)(null), 2), imgSrc = _b[0], setImgSrc = _b[1];
    var _c = __read((0, react_2.useState)({
        topLeftXPixels: 20,
        topLeftYPixels: 20,
        bottomRightXPixels: 20,
        bottomRightYPixels: 20,
    }), 2), config = _c[0], setConfig = _c[1];
    var _d = __read((0, react_2.useState)({ x: 300, y: 200 }), 2), imageSize = _d[0], setImageSize = _d[1];
    var setConfigValue = (0, react_2.useCallback)(function (key, value) {
        var num = Number(value);
        if (isNaN(num)) {
            return;
        }
        setConfig(function (c) {
            var _a;
            return (__assign(__assign({}, c), (_a = {}, _a[key] = num, _a)));
        });
    }, []);
    return ((0, jsx_runtime_1.jsxs)(react_1.Box, { position: "fixed", top: 0, left: 0, width: "100vw", height: "100vh", display: "flex", children: [(0, jsx_runtime_1.jsx)(react_1.Box, { height: "100%", width: "100%", backgroundColor: "gray.800", display: "flex", alignItems: "center", justifyContent: "center", children: (0, jsx_runtime_1.jsx)(react_1.Box, { backgroundColor: "gray.50", width: "".concat(imageSize.x, "px"), height: "".concat(imageSize.y, "px"), children: imgSrc ? ((0, jsx_runtime_1.jsx)(NinePatchImage_1.NinePatchImage, { src: imgSrc, config: config, showGrid: true })) : null }) }), (0, jsx_runtime_1.jsxs)(react_1.Box, { height: "100%", width: "50%", padding: 4, children: [(0, jsx_runtime_1.jsx)(FileUploadButton_1.FileUploadButton, { text: (_a = imgSrc === null || imgSrc === void 0 ? void 0 : imgSrc.slice(0, 10)) !== null && _a !== void 0 ? _a : "Upload Image", onLoad: function (files) {
                            var file = files === null || files === void 0 ? void 0 : files[0];
                            if (!file) {
                                return;
                            }
                            setImgSrc(URL.createObjectURL(file));
                        } }), (0, jsx_runtime_1.jsx)(react_1.Box, { fontWeight: "bold", marginBottom: 2, marginTop: 2, children: "Image Width" }), (0, jsx_runtime_1.jsx)(react_1.Input, { value: imageSize.x, onChange: function (e) {
                            var num = Number(e.target.value);
                            if (isNaN(num)) {
                                return;
                            }
                            setImageSize(function (i) { return (__assign(__assign({}, i), { x: num })); });
                        } }), (0, jsx_runtime_1.jsx)(react_1.Box, { fontWeight: "bold", marginBottom: 2, marginTop: 2, children: "Image Height" }), (0, jsx_runtime_1.jsx)(react_1.Input, { value: imageSize.y, onChange: function (e) {
                            var num = Number(e.target.value);
                            if (isNaN(num)) {
                                return;
                            }
                            setImageSize(function (i) { return (__assign(__assign({}, i), { y: num })); });
                        } }), (0, jsx_runtime_1.jsx)(react_1.Box, { fontWeight: "bold", marginBottom: 2, marginTop: 2, children: "Top Left X Pixels" }), (0, jsx_runtime_1.jsx)(react_1.Input, { value: config.topLeftXPixels, onChange: function (e) {
                            setConfigValue("topLeftXPixels", e.target.value);
                        } }), (0, jsx_runtime_1.jsx)(react_1.Box, { fontWeight: "bold", marginBottom: 2, marginTop: 2, children: "Top Left Y Pixels" }), (0, jsx_runtime_1.jsx)(react_1.Input, { value: config.topLeftYPixels, onChange: function (e) {
                            setConfigValue("topLeftYPixels", e.target.value);
                        } }), (0, jsx_runtime_1.jsx)(react_1.Box, { fontWeight: "bold", marginBottom: 2, marginTop: 2, children: "Bottom Right X Pixels" }), (0, jsx_runtime_1.jsx)(react_1.Input, { value: config.bottomRightXPixels, onChange: function (e) {
                            setConfigValue("bottomRightXPixels", e.target.value);
                        } }), (0, jsx_runtime_1.jsx)(react_1.Box, { fontWeight: "bold", marginBottom: 2, marginTop: 2, children: "Bottom Right Y Pixels" }), (0, jsx_runtime_1.jsx)(react_1.Input, { value: config.bottomRightYPixels, onChange: function (e) {
                            setConfigValue("bottomRightYPixels", e.target.value);
                        } }), (0, jsx_runtime_1.jsx)(react_1.Box, { fontWeight: "bold", marginBottom: 2, marginTop: 2, children: "Config" }), (0, jsx_runtime_1.jsx)("pre", { children: JSON.stringify(config, null, 2) })] })] }));
};
exports.NinePatchImageEditor = NinePatchImageEditor;
