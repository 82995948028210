"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NetworkPhysicsOutlineShapeType = void 0;
var NetworkPhysicsOutlineShapeType;
(function (NetworkPhysicsOutlineShapeType) {
    NetworkPhysicsOutlineShapeType["Box"] = "box";
    NetworkPhysicsOutlineShapeType["Plane"] = "plane";
    NetworkPhysicsOutlineShapeType["Sphere"] = "sphere";
    NetworkPhysicsOutlineShapeType["Cylinder"] = "cylinder";
})(NetworkPhysicsOutlineShapeType = exports.NetworkPhysicsOutlineShapeType || (exports.NetworkPhysicsOutlineShapeType = {}));
