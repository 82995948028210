"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NinePatchImage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("@chakra-ui/react");
var react_2 = require("react");
var canvas = document.createElement("canvas");
var ctx = canvas.getContext("2d");
if (!ctx) {
    throw new Error("Canvas rendering context is not supported");
}
var clearCanvas = function () {
    ctx.clearRect(0, 0, canvas.width, canvas.height);
};
var NinePatchImage = function (_a) {
    var config = _a.config, showGrid = _a.showGrid, _b = _a.gridColor, gridColor = _b === void 0 ? "red.500" : _b, src = _a.src, props = __rest(_a, ["config", "showGrid", "gridColor", "src"]);
    var _c = __read((0, react_2.useState)(false), 2), imageLoaded = _c[0], setImageLoaded = _c[1];
    var imageRef = (0, react_2.useRef)(new Image());
    var imageData = (0, react_2.useMemo)(function () {
        if (!imageLoaded) {
            return null;
        }
        // Top Left Corner
        clearCanvas();
        canvas.width = config.topLeftXPixels;
        canvas.height = config.topLeftYPixels;
        ctx.drawImage(imageRef.current, 0, 0, imageRef.current.width, imageRef.current.height);
        var topLeft = canvas.toDataURL();
        // Top Center
        clearCanvas();
        canvas.width =
            imageRef.current.width -
                config.topLeftXPixels -
                config.bottomRightXPixels;
        canvas.height = config.topLeftYPixels;
        ctx.drawImage(imageRef.current, -1 * config.topLeftXPixels, 0, imageRef.current.width, imageRef.current.height);
        var topCenter = canvas.toDataURL();
        // Top Right Corner
        clearCanvas();
        canvas.width = config.bottomRightXPixels;
        canvas.height = config.topLeftYPixels;
        ctx.drawImage(imageRef.current, -1 * (imageRef.current.width - config.bottomRightXPixels), 0, imageRef.current.width, imageRef.current.height);
        var topRight = canvas.toDataURL();
        // Middle Left
        clearCanvas();
        canvas.width = config.topLeftXPixels;
        canvas.height =
            imageRef.current.height -
                config.topLeftYPixels -
                config.bottomRightYPixels;
        ctx.drawImage(imageRef.current, 0, -1 * config.topLeftYPixels, imageRef.current.width, imageRef.current.height);
        var middleLeft = canvas.toDataURL();
        // Center
        clearCanvas();
        canvas.width =
            imageRef.current.width -
                config.topLeftXPixels -
                config.bottomRightXPixels;
        canvas.height =
            imageRef.current.height -
                config.topLeftYPixels -
                config.bottomRightYPixels;
        ctx.drawImage(imageRef.current, -1 * config.topLeftXPixels, -1 * config.topLeftYPixels, imageRef.current.width, imageRef.current.height);
        var center = canvas.toDataURL();
        // Middle Right
        clearCanvas();
        canvas.width = config.bottomRightXPixels;
        canvas.height =
            imageRef.current.height -
                config.topLeftYPixels -
                config.bottomRightYPixels;
        ctx.drawImage(imageRef.current, -1 * (imageRef.current.width - config.bottomRightXPixels), -1 * config.topLeftYPixels, imageRef.current.width, imageRef.current.height);
        var middleRight = canvas.toDataURL();
        // Bottom Left
        clearCanvas();
        canvas.width = config.topLeftXPixels;
        canvas.height = config.bottomRightYPixels;
        ctx.drawImage(imageRef.current, 0, -1 * (imageRef.current.height - config.bottomRightYPixels), imageRef.current.width, imageRef.current.height);
        var bottomLeft = canvas.toDataURL();
        // Bottom Center
        clearCanvas();
        canvas.width =
            imageRef.current.width -
                config.topLeftXPixels -
                config.bottomRightXPixels;
        canvas.height = config.bottomRightYPixels;
        ctx.drawImage(imageRef.current, -1 * config.topLeftXPixels, -1 * (imageRef.current.height - config.bottomRightYPixels), imageRef.current.width, imageRef.current.height);
        var bottomCenter = canvas.toDataURL();
        // Bottom Right
        clearCanvas();
        canvas.width = config.bottomRightXPixels;
        canvas.height = config.bottomRightYPixels;
        ctx.drawImage(imageRef.current, -1 * (imageRef.current.width - config.bottomRightXPixels), -1 * (imageRef.current.height - config.bottomRightYPixels), imageRef.current.width, imageRef.current.height);
        var bottomRight = canvas.toDataURL();
        return {
            topLeft: topLeft,
            topCenter: topCenter,
            topRight: topRight,
            middleLeft: middleLeft,
            center: center,
            middleRight: middleRight,
            bottomLeft: bottomLeft,
            bottomCenter: bottomCenter,
            bottomRight: bottomRight,
        };
    }, [
        imageLoaded,
        config.bottomRightXPixels,
        config.bottomRightYPixels,
        config.topLeftXPixels,
        config.topLeftYPixels,
    ]);
    // Load image whenever src changes
    (0, react_2.useEffect)(function () {
        setImageLoaded(false);
        imageRef.current.src = src;
        imageRef.current.onload = function () {
            setImageLoaded(true);
        };
    }, [src]);
    if (!imageData) {
        return null;
    }
    return ((0, jsx_runtime_1.jsxs)(react_1.Box, __assign({ display: "flex", flexDir: "column", height: "100%", width: "100%", position: "relative" }, props, { children: [showGrid ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(react_1.Box, { width: "1px", height: "100%", position: "absolute", top: 0, left: "".concat(config.topLeftXPixels, "px"), transform: "translateX(-50%)", backgroundColor: gridColor }), (0, jsx_runtime_1.jsx)(react_1.Box, { width: "1px", height: "100%", position: "absolute", top: 0, right: "".concat(config.bottomRightXPixels, "px"), transform: "translateX(50%)", backgroundColor: gridColor }), (0, jsx_runtime_1.jsx)(react_1.Box, { width: "100%", height: "1px", position: "absolute", top: "".concat(config.topLeftYPixels, "px"), left: 0, transform: "translateY(-50%)", backgroundColor: gridColor }), (0, jsx_runtime_1.jsx)(react_1.Box, { width: "100%", height: "1px", position: "absolute", bottom: "".concat(config.bottomRightYPixels, "px"), left: 0, transform: "translateY(50%)", backgroundColor: gridColor })] })) : null, (0, jsx_runtime_1.jsxs)(react_1.Box, { display: "flex", width: "100%", height: "".concat(config.topLeftYPixels, "px"), children: [(0, jsx_runtime_1.jsx)("img", { src: imageData.topLeft, alt: "topLeft", style: {
                            width: "".concat(config.topLeftXPixels, "px"),
                            height: "".concat(config.topLeftYPixels, "px"),
                        } }), (0, jsx_runtime_1.jsx)("img", { src: imageData.topCenter, alt: "topCenter", style: {
                            width: "calc(100% - ".concat(config.topLeftXPixels, "px - ").concat(config.bottomRightXPixels, "px)"),
                            height: "".concat(config.topLeftYPixels, "px"),
                        } }), (0, jsx_runtime_1.jsx)("img", { src: imageData.topRight, alt: "topRight", style: {
                            width: "".concat(config.bottomRightXPixels, "px"),
                            height: "".concat(config.topLeftYPixels, "px"),
                        } })] }), (0, jsx_runtime_1.jsxs)(react_1.Box, { display: "flex", width: "100%", height: "calc(100% - ".concat(config.topLeftYPixels, "px - ").concat(config.bottomRightYPixels, "px)"), children: [(0, jsx_runtime_1.jsx)("img", { src: imageData.middleLeft, alt: "middleLeft", style: {
                            width: "".concat(config.topLeftXPixels, "px"),
                            height: "100%",
                        } }), (0, jsx_runtime_1.jsx)("img", { src: imageData.center, alt: "center", style: {
                            width: "calc(100% - ".concat(config.topLeftXPixels, "px - ").concat(config.bottomRightXPixels, "px)"),
                            height: "100%",
                        } }), (0, jsx_runtime_1.jsx)("img", { src: imageData.middleRight, alt: "middleRight", style: {
                            width: "".concat(config.bottomRightXPixels, "px"),
                            height: "100%",
                        } })] }), (0, jsx_runtime_1.jsxs)(react_1.Box, { display: "flex", width: "100%", height: "".concat(config.topLeftYPixels, "px"), children: [(0, jsx_runtime_1.jsx)("img", { src: imageData.bottomLeft, alt: "bottomLeft", style: {
                            width: "".concat(config.topLeftXPixels, "px"),
                            height: "".concat(config.bottomRightYPixels, "px"),
                        } }), (0, jsx_runtime_1.jsx)("img", { src: imageData.bottomCenter, alt: "bottomCenter", style: {
                            width: "calc(100% - ".concat(config.topLeftXPixels, "px - ").concat(config.bottomRightXPixels, "px)"),
                            height: "".concat(config.bottomRightYPixels, "px"),
                        } }), (0, jsx_runtime_1.jsx)("img", { src: imageData.bottomRight, alt: "bototmRight", style: {
                            width: "".concat(config.bottomRightXPixels, "px"),
                            height: "".concat(config.bottomRightYPixels, "px"),
                        } })] })] })));
};
exports.NinePatchImage = NinePatchImage;
