"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_CHARACTER_OPTIONS = exports.CHARACTER_EYE_COLORS = exports.CHARACTER_SKIN_COLORS = exports.CHARACTER_HAIR_COLORS = exports.MIN_CHARACTER_WIDTH_SCALE = exports.MAX_CHARACTER_WIDTH_SCALE = exports.MIN_CHARACTER_HEIGHT_SCALE = exports.MAX_CHARACTER_HEIGHT_SCALE = exports.CharacterMoustacheType = exports.CharacterBeardType = exports.CharacterHairTailType = exports.CharacterHairSideType = exports.CharacterHairBackType = exports.CharacterHairFringeType = exports.CharacterHairBaseType = exports.CharacterEyesType = exports.CharacterGender = void 0;
var CharacterGender;
(function (CharacterGender) {
    CharacterGender["Male"] = "male";
    CharacterGender["Female"] = "female";
})(CharacterGender || (exports.CharacterGender = CharacterGender = {}));
var CharacterEyesType;
(function (CharacterEyesType) {
    CharacterEyesType["Normal"] = "eyesNormal";
    CharacterEyesType["Cute"] = "eyesCute";
    CharacterEyesType["Angry"] = "eyesAngry";
    CharacterEyesType["Sullen"] = "eyesSullen";
})(CharacterEyesType || (exports.CharacterEyesType = CharacterEyesType = {}));
var CharacterHairBaseType;
(function (CharacterHairBaseType) {
    CharacterHairBaseType["Bald"] = "bald";
    CharacterHairBaseType["Basic"] = "hairBaseBasic";
    CharacterHairBaseType["Spiky"] = "hairBaseSpikey";
    CharacterHairBaseType["Braided"] = "hairBaseBraided";
})(CharacterHairBaseType || (exports.CharacterHairBaseType = CharacterHairBaseType = {}));
var CharacterHairFringeType;
(function (CharacterHairFringeType) {
    CharacterHairFringeType["None"] = "none";
    CharacterHairFringeType["Side"] = "hairFringeSide";
    CharacterHairFringeType["CombedUp"] = "hairFringeCombedUp";
    CharacterHairFringeType["MidPart"] = "hairFringeMidPart";
    CharacterHairFringeType["SideLong"] = "hairFringeSideLong";
    CharacterHairFringeType["Straight"] = "hairFringeStraight";
})(CharacterHairFringeType || (exports.CharacterHairFringeType = CharacterHairFringeType = {}));
var CharacterHairBackType;
(function (CharacterHairBackType) {
    CharacterHairBackType["None"] = "none";
    CharacterHairBackType["Short"] = "hairBackShort";
    CharacterHairBackType["Tied"] = "hairBackTied";
})(CharacterHairBackType || (exports.CharacterHairBackType = CharacterHairBackType = {}));
var CharacterHairSideType;
(function (CharacterHairSideType) {
    CharacterHairSideType["None"] = "none";
    CharacterHairSideType["Short"] = "hairSideShort";
})(CharacterHairSideType || (exports.CharacterHairSideType = CharacterHairSideType = {}));
var CharacterHairTailType;
(function (CharacterHairTailType) {
    CharacterHairTailType["None"] = "none";
    CharacterHairTailType["Pony"] = "hairTailPony";
    CharacterHairTailType["Spiky"] = "hairTailSpiky";
    CharacterHairTailType["Twin"] = "hairTailTwin";
})(CharacterHairTailType || (exports.CharacterHairTailType = CharacterHairTailType = {}));
var CharacterBeardType;
(function (CharacterBeardType) {
    CharacterBeardType["None"] = "none";
    CharacterBeardType["Small"] = "beardSmall";
})(CharacterBeardType || (exports.CharacterBeardType = CharacterBeardType = {}));
var CharacterMoustacheType;
(function (CharacterMoustacheType) {
    CharacterMoustacheType["None"] = "none";
    CharacterMoustacheType["Small"] = "moustacheSmall";
})(CharacterMoustacheType || (exports.CharacterMoustacheType = CharacterMoustacheType = {}));
exports.MAX_CHARACTER_HEIGHT_SCALE = 70;
exports.MIN_CHARACTER_HEIGHT_SCALE = -50;
exports.MAX_CHARACTER_WIDTH_SCALE = 70;
exports.MIN_CHARACTER_WIDTH_SCALE = -50;
exports.CHARACTER_HAIR_COLORS = [
    "#506372",
    "#890a24",
    "#97894e",
    "#FEFCBF",
    "#F8D26B",
    "#B1BCD5",
    "#949597",
    "#18100E",
    "#A7CEC6",
    "#7A888B",
    "#4E4D52",
    "#514E3C",
    "#866F3F",
    "#90524C",
    "#78471E",
    "#AAAC4F",
    "#56361B",
    "#F687B3",
];
exports.CHARACTER_SKIN_COLORS = [
    "#FFCC99",
    "#FEB186",
    "#DFC183",
    "#CEAB69",
    "#B98865",
    "#AA724B",
    "#935D37",
    "#CBACA3",
    "#C0A183",
    "#C18E74",
    "#EBCDA8",
    "#CAA661",
    "#7B4B2A",
    "#573719",
    "#483728",
];
exports.CHARACTER_EYE_COLORS = [
    "#18100E",
    "#F8D26B",
    "#B1BCD5",
    "#949597",
    "#A7CEC6",
    "#7A888B",
    "#4E4D52",
    "#514E3C",
    "#866F3F",
    "#90524C",
    "#78471E",
    "#AAAC4F",
    "#56361B",
];
exports.DEFAULT_CHARACTER_OPTIONS = {
    gender: CharacterGender.Male,
    eyeColor: exports.CHARACTER_EYE_COLORS[0],
    eyesType: CharacterEyesType.Normal,
    skinColor: exports.CHARACTER_SKIN_COLORS[0],
    heightScale: 0,
    widthScale: 0,
    hairBaseType: CharacterHairBaseType.Basic,
    hairColor: exports.CHARACTER_HAIR_COLORS[0],
    hairFringeType: CharacterHairFringeType.None,
    hairBackType: CharacterHairBackType.None,
    hairSideType: CharacterHairSideType.None,
    hairTailType: CharacterHairTailType.None,
    beardType: CharacterBeardType.None,
    moustacheType: CharacterMoustacheType.None,
};
