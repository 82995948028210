"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UniformType = exports.isGLSLCodeDocumentType = exports.isGLSLCodeDocument = exports.CodeDocumentType = void 0;
var CodeDocumentType;
(function (CodeDocumentType) {
    CodeDocumentType["FragmentShader"] = "fragmentShader";
    CodeDocumentType["VertexShader"] = "vertexShader";
    CodeDocumentType["Typescript"] = "typescript";
})(CodeDocumentType || (exports.CodeDocumentType = CodeDocumentType = {}));
var isGLSLCodeDocument = function (d) {
    return (0, exports.isGLSLCodeDocumentType)(d.type);
};
exports.isGLSLCodeDocument = isGLSLCodeDocument;
/**
 * Checks is shader type
 */
var isGLSLCodeDocumentType = function (t) {
    return (t === CodeDocumentType.FragmentShader || t === CodeDocumentType.VertexShader);
};
exports.isGLSLCodeDocumentType = isGLSLCodeDocumentType;
var UniformType;
(function (UniformType) {
    UniformType["String"] = "string";
    UniformType["Number"] = "number";
    UniformType["Boolean"] = "boolean";
    UniformType["Image"] = "image";
    UniformType["Time"] = "time";
    UniformType["Vector2"] = "vector2";
    UniformType["Vector3"] = "vector3";
})(UniformType || (exports.UniformType = UniformType = {}));
