"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.System = void 0;
var realms_utils_1 = require("realms-utils");
// Applies changes to gameObjects
var System = /** @class */ (function () {
    function System(
    // The targets are the types of the gameObjects that the system
    // is responsible for managing. This should never change
    targets, 
    // The set of System types that must run before
    // this system
    dependencies) {
        if (dependencies === void 0) { dependencies = []; }
        this.targets = targets;
        this.dependencies = dependencies;
        this.gameObjectSystemManager = null;
        this.eventManager = new realms_utils_1.EventManager();
        this.addListener = this.eventManager.addListener;
        this.removeListener = this.eventManager.removeListener;
        this.once = this.eventManager.once;
        this.dispatch = this.eventManager.dispatch;
    }
    Object.defineProperty(System.prototype, "type", {
        get: function () {
            return Object.getPrototypeOf(this).constructor;
        },
        enumerable: false,
        configurable: true
    });
    // Dont call this manually
    System.prototype._setGameObjectSystemManager = function (csm) {
        this.gameObjectSystemManager = csm;
    };
    /*
     * Gets the gameObjects managed by this system
     */
    System.prototype.getTargetGameObjects = function () {
        if (this.gameObjectSystemManager) {
            return this.gameObjectSystemManager.getSystemGameObjects(this.type);
        }
        // Not in realm yet so it wont have any managed gameObjects
        return [];
    };
    System.prototype.getDependencies = function () {
        return this.dependencies;
    };
    System.prototype.addDependency = function (systemType) {
        if (!this.dependencies.includes(systemType)) {
            this.dependencies = __spreadArray(__spreadArray([], __read(this.dependencies), false), [systemType], false);
        }
        return this;
    };
    System.prototype.getTargets = function () {
        return this.targets;
    };
    /*
     * Called when a gameObject managed by this system enters the scene
     */
    System.prototype.onTargetGameObjectEnter = function (gameObject) { };
    /*
     * Called when a gameObject managed by this system exits the scene
     */
    System.prototype.onTargetGameObjectExit = function (gameObject) { };
    /**
     * Called when the system is added for the first time
     */
    System.prototype.onAdd = function (realm) { };
    /**
     * Called at the start of the game step
     */
    System.prototype.onGameStepStart = function (delta, realm) { };
    /**
     * This will be called by the system runner before any gameObjects
     * belonging to this system is stepped
     */
    System.prototype.processBeforeStep = function (delta, realm) { };
    // Runs a game loop step on a given gameObject. Delta is in milliseconds.
    // Override to hook into this. Note: Systems that have no target gameObjects
    // will not run this.
    System.prototype.step = function (delta, gameObject, realm) { };
    /**
     * This is called after all gameObjects of this system is processed
     */
    System.prototype.processAfterStep = function (delta, realm) { };
    /**
     * Called when the game step ends
     */
    System.prototype.onGameStepEnd = function (delta, realm) { };
    // Cleans up this system
    System.prototype.clean = function () { };
    return System;
}());
exports.System = System;
