"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PluginEventType = void 0;
/**
 * All the events received by the plugin from the core application
 */
var PluginEventType;
(function (PluginEventType) {
    PluginEventType["FunctionCall"] = "functionCall";
    PluginEventType["SDKFunctionCallResponse"] = "sdkFunctionCallResponse";
    PluginEventType["EditorThemeChange"] = "editorThemeChange";
    PluginEventType["PluginStateValueChange"] = "pluginStateValueChange";
    PluginEventType["OpenPluginDataObjectCreateModal"] = "openPluginDataObjectCreateModal";
    PluginEventType["SelectedDataObjectChange"] = "selectedDataObjectChange";
    PluginEventType["DisplayedWindowChange"] = "displayedWindowChange";
    PluginEventType["PluginSceneWidgetClick"] = "pluginSceneWidgetClick";
    PluginEventType["PluginClientStateValueChange"] = "pluginClientStateValueChange";
})(PluginEventType || (exports.PluginEventType = PluginEventType = {}));
