"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.KeyboardListener = void 0;
var realms_utils_1 = require("realms-utils");
var KeyboardListener = /** @class */ (function () {
    function KeyboardListener() {
        var _this = this;
        this.eventManager = new realms_utils_1.EventManager();
        this.event = this.eventManager.asEventEmitter();
        // An internal array of pressedKeys
        this.pressedKeys = [];
        /**
         * Handles a keyboardEvent. You can push a keyboardEvent into this
         * if you wanted to handle keyboard events from other documents such
         * as inside iframes
         */
        this.onKeyboardEvent = function (e) {
            if (e.type === "keyup") {
                _this.pressedKeys = [];
            }
            else if (e.type === "keydown") {
                _this.pressedKeys = __spreadArray(__spreadArray([], __read(_this.pressedKeys.filter(function (k) { return k !== e.code; })), false), [
                    e.code,
                ], false);
            }
            _this.eventManager.dispatch("keyboardEvent", e);
        };
    }
    /**
     * Subscribes to changes
     */
    KeyboardListener.prototype.subscribe = function () {
        window.addEventListener("keyup", this.onKeyboardEvent);
        window.addEventListener("keydown", this.onKeyboardEvent);
    };
    /**
     * Unsubscribes from changes
     */
    KeyboardListener.prototype.unsubscribe = function () {
        window.removeEventListener("keyup", this.onKeyboardEvent);
        window.removeEventListener("keydown", this.onKeyboardEvent);
    };
    /**
     * Gets all the pressed keys
     */
    KeyboardListener.prototype.getPressedKeys = function () {
        return this.pressedKeys;
    };
    return KeyboardListener;
}());
exports.KeyboardListener = KeyboardListener;
