"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssetWidgetMode = exports.DEFAULT_SHAPE_COLOR = exports.EXPORTED_REALM_FILE_EXTENSION = exports.PROJECT_FILE_EXTENSION = void 0;
exports.PROJECT_FILE_EXTENSION = ".project";
exports.EXPORTED_REALM_FILE_EXTENSION = ".realm";
exports.DEFAULT_SHAPE_COLOR = "808080";
var AssetWidgetMode;
(function (AssetWidgetMode) {
    AssetWidgetMode["List"] = "list";
    AssetWidgetMode["Grid"] = "grid";
})(AssetWidgetMode || (exports.AssetWidgetMode = AssetWidgetMode = {}));
