"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditorFunctionCallType = void 0;
/**
 * The type of a function call made by the editor
 * to the plugin
 */
var EditorFunctionCallType;
(function (EditorFunctionCallType) {
    EditorFunctionCallType["LoadPlugin"] = "loadPlugin";
    EditorFunctionCallType["DestroyPlugin"] = "destroyPlugin";
    EditorFunctionCallType["BuildPluginDataObject"] = "buildPluginDataObject";
    EditorFunctionCallType["TransformState"] = "transformState";
    EditorFunctionCallType["TransformRealmBlueprint"] = "transformRealmBlueprint";
})(EditorFunctionCallType || (exports.EditorFunctionCallType = EditorFunctionCallType = {}));
