"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrackingCameraSystem = void 0;
var realms_engine_1 = require("realms-engine");
var render3D_1 = require("../render3D");
var render3DGameObject_1 = require("../render3D/render3DGameObject");
var TrackingCameraSystem = /** @class */ (function (_super) {
    __extends(TrackingCameraSystem, _super);
    function TrackingCameraSystem(camera, options) {
        if (options === void 0) { options = {}; }
        var _a, _b, _c, _d, _e, _f;
        var _this = _super.call(this, [render3DGameObject_1.Render3DGameObject]) || this;
        _this.camera = camera;
        /*
         * The angle parallel to the vertical direction clamped
         * between -pi / 2 and +pi / 2 which represents if the camera is looking up
         * or down. 0 means camera is looking straight, negative angle means camera is looking up
         * and positive angle means camera is looking down
         */
        _this.vAngle = 0;
        /*
         * The angle around the vertical axis representing where the camera is
         * relative to the player represented as a normalised vector
         */
        _this.hAngle = 0;
        /*
         * Where the camera orbits around
         */
        _this.target = (0, realms_engine_1.vector3Zero)();
        _this.lastTarget = (0, realms_engine_1.vector3Zero)();
        _this.lookOffset = (_a = options.lookOffset) !== null && _a !== void 0 ? _a : (0, realms_engine_1.vector3Zero)();
        _this.cameraOffset = (_b = options.cameraOffset) !== null && _b !== void 0 ? _b : (0, realms_engine_1.vector3Zero)();
        _this.distance = (_c = options.distance) !== null && _c !== void 0 ? _c : 20;
        _this.enableTargetDamping = (_d = options.enableTargetDamping) !== null && _d !== void 0 ? _d : false;
        _this.targetDamping = new realms_engine_1.SmoothDampVector3(function () { return _this.lastTarget; }, (_e = options.targetDampingMaxSpeed) !== null && _e !== void 0 ? _e : 200);
        _this.targetDampingSmoothTime = (_f = options.targetDampingSmoothTime) !== null && _f !== void 0 ? _f : 300;
        return _this;
    }
    TrackingCameraSystem.prototype.setTarget = function (target) {
        this.target = target;
        return this;
    };
    TrackingCameraSystem.prototype.setLookOffset = function (offset) {
        this.lookOffset = offset;
        return this;
    };
    TrackingCameraSystem.prototype.setCameraOffset = function (offset) {
        this.cameraOffset = offset;
        return this;
    };
    /*
     * Gets the H Angle represented as a unit vector
     */
    TrackingCameraSystem.prototype.getHVector = function () {
        return (0, realms_engine_1.vector2FromAngle)(this.hAngle);
    };
    TrackingCameraSystem.prototype.rotateHAngle = function (radians) {
        this.hAngle = this.hAngle + (radians % (2 * Math.PI));
        if (this.hAngle < 0) {
            this.hAngle = this.hAngle + 2 * Math.PI;
        }
        else if (this.hAngle > 2 * Math.PI) {
            this.hAngle = this.hAngle - 2 * Math.PI;
        }
    };
    TrackingCameraSystem.prototype.rotateVAngle = function (radians) {
        this.vAngle = Math.max(-Math.PI * 0.5, Math.min(Math.PI * 0.5, this.vAngle + radians));
    };
    TrackingCameraSystem.prototype.processBeforeStep = function (delta, realm) {
        // Displacement of the camera from the target
        var cameraDisplacement = (0, realms_engine_1.vector3Add)((0, realms_engine_1.vector3Multiply)((0, realms_engine_1.vector3Normalize)({
            /*
             * Note that for THREEJS y is the vertical axis
             * hence we have to switch them when converting from 2d
             */
            x: this.getHVector().x,
            z: this.getHVector().y,
            y: this.distance * Math.sin(this.vAngle),
        }), this.distance), this.cameraOffset);
        // Smooth the jittery data
        var targetPosition = this.target;
        if (this.enableTargetDamping) {
            targetPosition = this.targetDamping.damp(targetPosition, this.targetDampingSmoothTime, delta);
        }
        this.lastTarget = targetPosition;
        // Update position
        var newCameraPosition = (0, realms_engine_1.vector3Add)(targetPosition, cameraDisplacement);
        // Set camera position
        this.camera.position.copy((0, render3D_1.toThreeVector3)(newCameraPosition));
        // Look at target
        var lookPosition = (0, realms_engine_1.vector3Add)(targetPosition, this.lookOffset);
        this.camera.lookAt((0, render3D_1.toThreeVector3)(lookPosition));
    };
    return TrackingCameraSystem;
}(realms_engine_1.System));
exports.TrackingCameraSystem = TrackingCameraSystem;
