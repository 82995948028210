import { AssetManager } from "realms-three-utils";
import { AnimationAssetMap } from "./assetMaps/animationAssetMap";
import { ModelAssetMap } from "./assetMaps/modelAssetMap";
import { TextureAssetMap } from "./assetMaps/textures/textureAssetMap";
import { BaseCharacterAssetMap } from "./assetMaps/baseCharacterAssetMap";
import { WeaponAssetMap } from "./assetMaps/weaponAssetMap";

/**
 * All the assets to be loaded
 */
const MMOAssetMap = {
  ...AnimationAssetMap,
  ...ModelAssetMap,
  ...TextureAssetMap,
  ...BaseCharacterAssetMap,
  ...WeaponAssetMap,
} as const;

export const MMOAssetManager = new AssetManager(MMOAssetMap);
