"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseCharacterClothing = exports.BaseCharacterEyes = exports.BaseCharacterBeard = exports.BaseCharacterMoustache = exports.BaseCharacterHairTail = exports.BaseCharacterHairSide = exports.BaseCharacterHairFringe = exports.BaseCharacterHairBack = exports.BaseCharacterHairBase = exports.BaseCharacterGender = exports.BaseCharacterRace = exports.Weapon = exports.BaseCharacterClass = void 0;
var BaseCharacterClass;
(function (BaseCharacterClass) {
    BaseCharacterClass["Warrior"] = "warrior";
    BaseCharacterClass["Rogue"] = "rogue";
    BaseCharacterClass["Mage"] = "mage";
})(BaseCharacterClass || (exports.BaseCharacterClass = BaseCharacterClass = {}));
var Weapon;
(function (Weapon) {
    Weapon["None"] = "none";
    Weapon["Sword"] = "sword";
    Weapon["Dagger"] = "dagger";
    Weapon["Staff"] = "staff";
})(Weapon || (exports.Weapon = Weapon = {}));
var BaseCharacterRace;
(function (BaseCharacterRace) {
    BaseCharacterRace["Human"] = "human";
    BaseCharacterRace["Elf"] = "elf";
    BaseCharacterRace["Orc"] = "orc";
})(BaseCharacterRace || (exports.BaseCharacterRace = BaseCharacterRace = {}));
var BaseCharacterGender;
(function (BaseCharacterGender) {
    BaseCharacterGender["Male"] = "male";
    BaseCharacterGender["Female"] = "female";
})(BaseCharacterGender || (exports.BaseCharacterGender = BaseCharacterGender = {}));
var BaseCharacterHairBase;
(function (BaseCharacterHairBase) {
    BaseCharacterHairBase["None"] = "none";
    BaseCharacterHairBase["Normal"] = "normal";
    BaseCharacterHairBase["Mohawk"] = "mohawk";
})(BaseCharacterHairBase || (exports.BaseCharacterHairBase = BaseCharacterHairBase = {}));
var BaseCharacterHairBack;
(function (BaseCharacterHairBack) {
    BaseCharacterHairBack["None"] = "none";
    BaseCharacterHairBack["Short"] = "short";
    BaseCharacterHairBack["Long"] = "long";
})(BaseCharacterHairBack || (exports.BaseCharacterHairBack = BaseCharacterHairBack = {}));
var BaseCharacterHairFringe;
(function (BaseCharacterHairFringe) {
    BaseCharacterHairFringe["None"] = "none";
    BaseCharacterHairFringe["Normal"] = "normal";
    BaseCharacterHairFringe["Straight"] = "straight";
    BaseCharacterHairFringe["MiddlePart"] = "middlePart";
    BaseCharacterHairFringe["MiddlePartThick"] = "middlePartThick";
})(BaseCharacterHairFringe || (exports.BaseCharacterHairFringe = BaseCharacterHairFringe = {}));
var BaseCharacterHairSide;
(function (BaseCharacterHairSide) {
    BaseCharacterHairSide["None"] = "none";
    BaseCharacterHairSide["Normal"] = "normal";
})(BaseCharacterHairSide || (exports.BaseCharacterHairSide = BaseCharacterHairSide = {}));
var BaseCharacterHairTail;
(function (BaseCharacterHairTail) {
    BaseCharacterHairTail["None"] = "none";
    BaseCharacterHairTail["Ponytail"] = "ponytail";
})(BaseCharacterHairTail || (exports.BaseCharacterHairTail = BaseCharacterHairTail = {}));
var BaseCharacterMoustache;
(function (BaseCharacterMoustache) {
    BaseCharacterMoustache["None"] = "none";
    BaseCharacterMoustache["Normal"] = "normal";
})(BaseCharacterMoustache || (exports.BaseCharacterMoustache = BaseCharacterMoustache = {}));
var BaseCharacterBeard;
(function (BaseCharacterBeard) {
    BaseCharacterBeard["None"] = "none";
    BaseCharacterBeard["Normal"] = "normal";
})(BaseCharacterBeard || (exports.BaseCharacterBeard = BaseCharacterBeard = {}));
var BaseCharacterEyes;
(function (BaseCharacterEyes) {
    BaseCharacterEyes["Normal"] = "normal";
    BaseCharacterEyes["Determined"] = "determined";
    BaseCharacterEyes["Curious"] = "curious";
    BaseCharacterEyes["Cool"] = "cool";
    BaseCharacterEyes["Kind"] = "kind";
    BaseCharacterEyes["Beautiful"] = "beautiful";
})(BaseCharacterEyes || (exports.BaseCharacterEyes = BaseCharacterEyes = {}));
var BaseCharacterClothing;
(function (BaseCharacterClothing) {
    BaseCharacterClothing["Normal"] = "normal";
})(BaseCharacterClothing || (exports.BaseCharacterClothing = BaseCharacterClothing = {}));
