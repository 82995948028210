"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditorCoreCommand = void 0;
/**
 * Commands that exist in the core editor
 */
var EditorCoreCommand;
(function (EditorCoreCommand) {
    EditorCoreCommand["CloseCurrentWindow"] = "closeCurrentWindow";
    EditorCoreCommand["CycleToLeftWindow"] = "cycleToLeftWindow";
    EditorCoreCommand["CycleToRightWindow"] = "cycleToRightWindow";
    EditorCoreCommand["Undo"] = "undo";
    EditorCoreCommand["Redo"] = "redo";
    EditorCoreCommand["ToggleShowPrimarySidebar"] = "toggleShowPrimarySidebar";
    EditorCoreCommand["Search"] = "search";
    EditorCoreCommand["EnlargeBrush"] = "enlargeBrush";
    EditorCoreCommand["ShrinkBrush"] = "shrinkBrush";
    EditorCoreCommand["CycleToNextBrushMode"] = "cycleToNextBrushMode";
    EditorCoreCommand["CycleToPrevBrushMode"] = "cycleToPrevBrushMode";
    EditorCoreCommand["ShowEditorStats"] = "showEditorStats";
    EditorCoreCommand["SaveCurrentBuffer"] = "saveCurrentBuffer";
    EditorCoreCommand["HideEditorStats"] = "hideEditorStats";
    EditorCoreCommand["ShowPressedKeys"] = "showPressedKeys";
    EditorCoreCommand["HidePressedKeys"] = "hidePressedKeys";
    EditorCoreCommand["OpenSettings"] = "openSettings";
})(EditorCoreCommand || (exports.EditorCoreCommand = EditorCoreCommand = {}));
