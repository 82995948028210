"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileUploadButton = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("@chakra-ui/react");
var react_2 = require("react");
var FileUploadButton = function (_a) {
    var onLoad = _a.onLoad, accept = _a.accept, text = _a.text;
    var fileUploadRef = (0, react_2.useRef)(null);
    var onClick = (0, react_2.useCallback)(function () {
        var _a, _b;
        if ((_a = fileUploadRef.current) === null || _a === void 0 ? void 0 : _a.value) {
            fileUploadRef.current.value = "";
        }
        (_b = fileUploadRef.current) === null || _b === void 0 ? void 0 : _b.click();
    }, []);
    /*
     * Listen to file read
     */
    (0, react_2.useEffect)(function () {
        var onFileChange = function (e) {
            onLoad(e.target.files);
        };
        var fileRef = fileUploadRef.current;
        fileRef === null || fileRef === void 0 ? void 0 : fileRef.addEventListener("change", onFileChange, false);
        return function () {
            fileRef === null || fileRef === void 0 ? void 0 : fileRef.removeEventListener("change", onFileChange);
        };
    }, [onLoad]);
    return ((0, jsx_runtime_1.jsxs)(react_1.Button, { onClick: onClick, width: "100%", children: [text, (0, jsx_runtime_1.jsx)("input", { accept: accept, ref: fileUploadRef, type: "file", multiple: true, style: {
                    display: "none",
                } })] }));
};
exports.FileUploadButton = FileUploadButton;
