"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PhysicsBodyType = void 0;
var PhysicsBodyType;
(function (PhysicsBodyType) {
    PhysicsBodyType["Box"] = "box";
    PhysicsBodyType["Sphere"] = "sphere";
    PhysicsBodyType["Plane"] = "plane";
    PhysicsBodyType["Cylinder"] = "cylinder";
})(PhysicsBodyType || (exports.PhysicsBodyType = PhysicsBodyType = {}));
