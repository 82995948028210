import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import "./index.css";
import { ChakraProvider } from "@chakra-ui/react";
import { MMOTheme } from "./theme";
import { AuthProvider } from "realmsbase-sdk";
import { lazy, Suspense } from "react";
import { LoadingPage } from "./pages/LoadingPage";
import { AssetGuard } from "./page-guards/AssetGuard";
import { AdminGuard } from "./page-guards/AdminGuard";
import { createRealmsbaseRoutes } from "realmsbase-dashboard";
import { ServiceProvider } from "./services";
import { NinePatchImageEditor } from "mmo-ui";
import { getEnvironment } from "realms-react-utils";

console.log(`Arcadian Realms ${getEnvironment()} mode`);

// Code splitting pages

const LegacyCharacterSelectionPage = lazy(() =>
  import("./pages/legacy-character-selection-page/CharacterSelectionPage").then(
    ({ CharacterSelectionPage }) => ({ default: CharacterSelectionPage })
  )
);

const LegacyCharacterCreationPage = lazy(() =>
  import("./pages/legacy-character-creation-page/CharacterCreationPage").then(
    ({ CharacterCreationPage }) => ({ default: CharacterCreationPage })
  )
);

const CharacterCreationPage = lazy(() =>
  import("./pages/character-creation-page/CharacterCreationPage").then(
    ({ CharacterCreationPage }) => ({ default: CharacterCreationPage })
  )
);

const GamePage = lazy(() =>
  import("./pages/game-page/GamePage").then(({ GamePage }) => ({
    default: GamePage,
  }))
);

const LoginPage = lazy(() =>
  import("./pages/LoginPage").then(({ LoginPage }) => ({ default: LoginPage }))
);

const RegisterPage = lazy(() =>
  import("./pages/RegisterPage").then(({ RegisterPage }) => ({
    default: RegisterPage,
  }))
);

const SetTokenPage = lazy(() =>
  import("./pages/internal/SetTokenPage").then(({ SetTokenPage }) => ({
    default: SetTokenPage,
  }))
);

const AdminDashboardPage = lazy(() =>
  import("./pages/admin/admin-dashboard-page/AdminDashboardPage").then(
    ({ AdminDashboardPage }) => ({
      default: AdminDashboardPage,
    })
  )
);

const CharacterPlayground = lazy(() =>
  import(
    "./pages/admin/playground/character-playground-page/CharacterPlayground"
  ).then(({ CharacterPlayground }) => ({ default: CharacterPlayground }))
);

const ThreePlaygroundPage = lazy(() =>
  import(
    "./pages/admin/playground/three-playground-page/ThreePlaygroundPage"
  ).then(({ ThreePlaygroundPage }) => ({ default: ThreePlaygroundPage }))
);

const BaseCharacterPlayground = lazy(() =>
  import("./pages/dev/base-character-playground/BaseCharacterPlayground").then(
    ({ BaseCharacterPlayground }) => ({ default: BaseCharacterPlayground })
  )
);

const CanvasUIPage = lazy(() =>
  import("./pages/admin/playground/canvas-ui/CanvasUIPage").then(
    ({ CanvasUIPage }) => ({ default: CanvasUIPage })
  )
);

// Add to routes
const router = createBrowserRouter([
  {
    path: "/character",
    children: [
      {
        path: "select",
        element: (
          <AssetGuard>
            <LegacyCharacterSelectionPage />
          </AssetGuard>
        ),
      },
      {
        path: "create",
        element: (
          <AssetGuard>
            <LegacyCharacterCreationPage />
          </AssetGuard>
        ),
      },
      {
        path: "create-new",
        element: (
          <AssetGuard>
            <CharacterCreationPage />
          </AssetGuard>
        ),
      },
    ],
  },
  {
    path: "/internal",
    children: [
      {
        path: "set-token",
        element: <SetTokenPage />,
      },
    ],
  },
  {
    path: "/game",
    element: (
      <AssetGuard>
        <GamePage />
      </AssetGuard>
    ),
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/register",
    element: <RegisterPage />,
  },
  ...createRealmsbaseRoutes("/admin/realmsbase/"),
  {
    path: "/admin",
    children: [
      {
        path: "playground",
        children: [
          {
            path: "editor",
            element: (
              <AdminGuard>
                <AssetGuard>
                  <CharacterPlayground height={500} width={500} />
                </AssetGuard>
              </AdminGuard>
            ),
          },
          {
            path: "canvas-ui",
            element: <CanvasUIPage />,
          },
          {
            path: "",
            element: (
              <AdminGuard>
                <ThreePlaygroundPage />
              </AdminGuard>
            ),
          },
        ],
      },
      {
        path: "",
        element: (
          <AdminGuard>
            <AdminDashboardPage />
          </AdminGuard>
        ),
      },
    ],
  },
  {
    path: "/nine-patch-image",
    element: <NinePatchImageEditor />,
  },
  {
    path: "/base-character-playground",
    element: (
      <AssetGuard>
        <BaseCharacterPlayground />
      </AssetGuard>
    ),
  },
  // Dev pages
  {
    path: "/",
    element: <Navigate to="/login" />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ChakraProvider theme={MMOTheme}>
    <AuthProvider>
      <Suspense fallback={<LoadingPage />}>
        <ServiceProvider>
          <RouterProvider router={router} />
        </ServiceProvider>
      </Suspense>
    </AuthProvider>
  </ChakraProvider>
);
