import baseCharacterMaleAsset from "@assets/base_character_v2/base_character_male_rigged.fbx";
import baseCharacterMaleSkinShadowsAsset from "@assets/base_character_v2/textures/male_base_skin_shadows.png";
import baseCharacterMaleEyesNormalAsset from "@assets/base_character_v2/textures/male_eyes_normal.png";
import baseCharacterMaleEyesDeterminedAsset from "@assets/base_character_v2/textures/male_eyes_determined.png";
import baseCharacterMaleEyesCuriousAsset from "@assets/base_character_v2/textures/male_eyes_curious.png";
import baseCharacterMaleEyesCoolAsset from "@assets/base_character_v2/textures/male_eyes_cool.png";
import baseCharacterMaleEyesKindAsset from "@assets/base_character_v2/textures/male_eyes_kind.png";
import baseCharacterMaleEyesBeautifulAsset from "@assets/base_character_v2/textures/male_eyes_beautiful.png";
import baseCharacterMaleClothingAsset from "@assets/base_character_v2/textures/male_base_clothing.png";
import baseCharacterMaleIdleAnimationAsset from "@assets/base_character_v2/animations/idle_male.fbx";
import warriorMaleIdleAnimationAsset from "@assets/base_character_v2/animations/warrior_idle_male.fbx";
import rogueMaleIdleAnimationAsset from "@assets/base_character_v2/animations/rogue_idle_male.fbx";
import mageMaleIdleAnimationAsset from "@assets/base_character_v2/animations/mage_idle_male.fbx";
import baseCharacterMaleOrcTextureAsset from "@assets/base_character_v2/textures/male_orc_texture.png";
import baseCharacterMoustacheNormalAsset from "@assets/base_character_v2/moustache/moustache_normal.fbx";
import baseCharacterBeardNormalAsset from "@assets/base_character_v2/beard/beard_normal.fbx";
import baseCharacterFemaleAsset from "@assets/base_character_v2/base_character_female_rigged.fbx";
import baseCharacterFemaleSkinShadowsAsset from "@assets/base_character_v2/textures/female_base_skin_shadow.png";
import baseCharacterFemaleEyesNormalAsset from "@assets/base_character_v2/textures/female_eyes_normal.png";
import baseCharacterFemaleEyesDeterminedAsset from "@assets/base_character_v2/textures/female_eyes_determined.png";
import baseCharacterFemaleEyesCuriousAsset from "@assets/base_character_v2/textures/female_eyes_curious.png";
import baseCharacterFemaleEyesCoolAsset from "@assets/base_character_v2/textures/female_eyes_cool.png";
import baseCharacterFemaleEyesKindAsset from "@assets/base_character_v2/textures/female_eyes_kind.png";
import baseCharacterFemaleEyesBeautifulAsset from "@assets/base_character_v2/textures/female_eyes_beautiful.png";
import baseCharacterFemaleClothingAsset from "@assets/base_character_v2/textures/female_base_clothing.png";
import baseCharacterFemaleIdleAnimationAsset from "@assets/base_character_v2/animations/idle_female.fbx";
import warriorFemaleIdleAnimationAsset from "@assets/base_character_v2/animations/warrior_idle_female.fbx";
import rogueFemaleIdleAnimationAsset from "@assets/base_character_v2/animations/rogue_idle_female.fbx";
import mageFemaleIdleAnimationAsset from "@assets/base_character_v2/animations/mage_idle_female.fbx";
import baseCharacterFemaleOrcTextureAsset from "@assets/base_character_v2/textures/female_orc_texture.png";
import baseCharacterHairBaseNormalAsset from "@assets/base_character_v2/hair_base/hair_base_normal.fbx";
import baseCharacterHairBaseMohawkAsset from "@assets/base_character_v2/hair_base/hair_base_mohawk.fbx";
import baseCharacterHairBackShortAsset from "@assets/base_character_v2/hair_back/hair_back_short.fbx";
import baseCharacterHairBackLongAsset from "@assets/base_character_v2/hair_back/hair_back_long.fbx";
import baseCharacterHairFringeNormalAsset from "@assets/base_character_v2/hair_fringe/fringe_normal.fbx";
import baseCharacterHairFringeStraightAsset from "@assets/base_character_v2/hair_fringe/fringe_straight.fbx";
import baseCharacterHairFringeMiddlePartAsset from "@assets/base_character_v2/hair_fringe/fringe_middle_part.fbx";
import baseCharacterHairFringeMiddlePartThickAsset from "@assets/base_character_v2/hair_fringe/fringe_middle_part_thick.fbx";
import baseCharacterHairSideNormalAsset from "@assets/base_character_v2/hair_side/hair_side_normal.fbx";
import baseCharacterHairTailPonyTailAsset from "@assets/base_character_v2/hair_tail/hair_tail_ponytail.fbx";
import baseCharacterEarsHumanAsset from "@assets/base_character_v2/ears/ears_human.fbx";
import baseCharacterEarsElfAsset from "@assets/base_character_v2/ears/ears_elf.fbx";
import baseCharacterEarsOrcAsset from "@assets/base_character_v2/ears/ears_orc.fbx";

const CHARACTER_SCALE = 0.01;

const BaseMaleCharacterAssetMap = {
  baseCharacterMale: {
    fileName: baseCharacterMaleAsset,
    scale: CHARACTER_SCALE,
  },
  baseCharacterMaleSkinShadows: {
    fileName: baseCharacterMaleSkinShadowsAsset,
  },
  baseCharacterMaleEyesNormal: {
    fileName: baseCharacterMaleEyesNormalAsset,
  },
  baseCharacterMaleEyesDetermined: {
    fileName: baseCharacterMaleEyesDeterminedAsset,
  },
  baseCharacterMaleEyesCurious: {
    fileName: baseCharacterMaleEyesCuriousAsset,
  },
  baseCharacterMaleEyesCool: {
    fileName: baseCharacterMaleEyesCoolAsset,
  },
  baseCharacterMaleEyesKind: {
    fileName: baseCharacterMaleEyesKindAsset,
  },
  baseCharacterMaleEyesBeautiful: {
    fileName: baseCharacterMaleEyesBeautifulAsset,
  },
  baseCharacterMaleClothing: {
    fileName: baseCharacterMaleClothingAsset,
  },
  baseCharacterMaleIdleAnimation: {
    fileName: baseCharacterMaleIdleAnimationAsset,
  },
  warriorMaleIdleAnimation: {
    fileName: warriorMaleIdleAnimationAsset,
  },
  rogueMaleIdleAnimation: {
    fileName: rogueMaleIdleAnimationAsset,
  },
  mageMaleIdleAnimation: {
    fileName: mageMaleIdleAnimationAsset,
  },
  baseCharacterMaleOrcTexture: {
    fileName: baseCharacterMaleOrcTextureAsset,
  },
  baseCharacterMoustacheNormal: {
    unwrap: true,
    fileName: baseCharacterMoustacheNormalAsset,
  },
  baseCharacterBeardNormal: {
    unwrap: true,
    fileName: baseCharacterBeardNormalAsset,
  },
} as const;

const BaseFemaleCharacterAssetMap = {
  baseCharacterFemale: {
    scale: CHARACTER_SCALE,
    fileName: baseCharacterFemaleAsset,
  },
  baseCharacterFemaleSkinShadows: {
    fileName: baseCharacterFemaleSkinShadowsAsset,
  },
  baseCharacterFemaleEyesNormal: {
    fileName: baseCharacterFemaleEyesNormalAsset,
  },
  baseCharacterFemaleEyesDetermined: {
    fileName: baseCharacterFemaleEyesDeterminedAsset,
  },
  baseCharacterFemaleEyesCurious: {
    fileName: baseCharacterFemaleEyesCuriousAsset,
  },
  baseCharacterFemaleEyesCool: {
    fileName: baseCharacterFemaleEyesCoolAsset,
  },
  baseCharacterFemaleEyesKind: {
    fileName: baseCharacterFemaleEyesKindAsset,
  },
  baseCharacterFemaleEyesBeautiful: {
    fileName: baseCharacterFemaleEyesBeautifulAsset,
  },
  baseCharacterFemaleClothing: {
    fileName: baseCharacterFemaleClothingAsset,
  },
  baseCharacterFemaleIdleAnimation: {
    fileName: baseCharacterFemaleIdleAnimationAsset,
  },
  warriorFemaleIdleAnimation: {
    fileName: warriorFemaleIdleAnimationAsset,
  },
  rogueFemaleIdleAnimation: {
    fileName: rogueFemaleIdleAnimationAsset,
  },
  mageFemaleIdleAnimation: {
    fileName: mageFemaleIdleAnimationAsset,
  },
  baseCharacterFemaleOrcTexture: {
    fileName: baseCharacterFemaleOrcTextureAsset,
  },
};

const CommonBaseCharacterAssetMap = {
  baseCharacterHairBaseNormal: {
    unwrap: true,
    fileName: baseCharacterHairBaseNormalAsset,
  },
  baseCharacterHairBaseMohawk: {
    unwrap: true,
    fileName: baseCharacterHairBaseMohawkAsset,
  },
  baseCharacterHairBackShort: {
    unwrap: true,
    fileName: baseCharacterHairBackShortAsset,
  },
  baseCharacterHairBackLong: {
    unwrap: true,
    fileName: baseCharacterHairBackLongAsset,
  },
  baseCharacterHairFringeNormal: {
    unwrap: true,
    fileName: baseCharacterHairFringeNormalAsset,
  },
  baseCharacterHairFringeStraight: {
    unwrap: true,
    fileName: baseCharacterHairFringeStraightAsset,
  },
  baseCharacterHairFringeMiddlePart: {
    unwrap: true,
    fileName: baseCharacterHairFringeMiddlePartAsset,
  },
  baseCharacterHairFringeMiddlePartThick: {
    unwrap: true,
    fileName: baseCharacterHairFringeMiddlePartThickAsset,
  },
  baseCharacterHairSideNormal: {
    unwrap: true,
    fileName: baseCharacterHairSideNormalAsset,
  },
  baseCharacterHairTailPonyTail: {
    unwrap: true,
    fileName: baseCharacterHairTailPonyTailAsset,
  },
  baseCharacterEarsHuman: {
    unwrap: true,
    fileName: baseCharacterEarsHumanAsset,
  },
  baseCharacterEarsElf: {
    unwrap: true,
    fileName: baseCharacterEarsElfAsset,
  },
  baseCharacterEarsOrc: {
    unwrap: true,
    fileName: baseCharacterEarsOrcAsset,
  },
};

export const BaseCharacterAssetMap = {
  ...BaseMaleCharacterAssetMap,
  ...BaseFemaleCharacterAssetMap,
  ...CommonBaseCharacterAssetMap,
} as const;
